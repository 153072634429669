//muestra una celebración no activa
import { useState, useEffect, useContext, useRef } from "react";
import ICelebracion from "../components/Interfaces/ICelebracion";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import {
  ellipsisVertical,
  trash,
  add,
  pencil,
  close,
  power,
  closeCircle,
  save,
  chevronDownCircle,
  musicalNote,
  cubeOutline,
} from "ionicons/icons";
import CardGrupo from "../components/Canciones/CardGrupo";
import IGrupoCanciones from "../components/Interfaces/IGrupoCanciones";
import ICancion from "../components/Interfaces/ICancion";
import ICancionCelebracion from "../components/Interfaces/ICancionCelebracion";
import { ModalContenido } from "../components/Contenido";

interface ICelebracionPage {
  id: string;
}
interface Props extends RouteComponentProps<ICelebracionPage> {}

const CelebracionPage = (props: Props) => {
  const { params } = props.match;
  const { id } = params;
  const {
    listadoCelebraciones,
    grupoCanciones,
    canciones,
    mostrarLoading,
    activarListadoById,
    eliminarListadoById,
    getGruposBd,
    getcancionesTodas,
    addCancionesListado,
    ordenarEliminardeCelebracion,
    setCancionCelebracionVisible,
    seModificaCelebracion,
    setSeModificaCelebracion,
    celebracionActiva,
  } = useContext(CelebracionesContext);
  const history = useHistory();
  const [celebracion, setCelebracion] = useState<ICelebracion | null>(null);
  const [cargoCelebraciones, setCargoCelebraciones] = useState<boolean>(false);
  const modalEdicion = useRef<HTMLIonModalElement>(null);
  const modalAddCanciones = useRef<HTMLIonModalElement>(null);
  //const modalAddContenido = useRef<HTMLIonModalElement>(null);
  const listRef2 = useRef<HTMLIonListElement>(null);
  const [idPresentacion, setIdPresentacion] = useState<string>("");
  const [mostrarModalEdicion, setMostrarModalEdicion] =
    useState<boolean>(false);
  const [descripcion, setDescripcion] = useState<string | null>(null);
  const [nombreCelebracion, setNombreCelebracion] = useState<string | null>(
    null
  );

  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  let loc = useLocation();

  //================
  const [mostrarModalAddCancion, setMostrarModalAddCancion] =
    useState<boolean>(false);

  const [swiper, setSwiper] = useState<any>({});
  const [agrego, setAgrego] = useState(false);
  const [result, setResult] = useState<ICancion[] | []>([]);
  const [listadoEliminaOrdena, setListadoEliminaOrdena] = useState<any[]>([]);
  const [reordenarDesabilitado, setReordenarDesabilitado] =
    useState<boolean>(true);
  const [cancelaOrdenaElimina, setCancelaOrdenaElimina] =
    useState<boolean>(false);
  const [valueSearch, setValueSearch] = useState("");
  const [mostrarModalAddContenido, setMostrarModalAddContenido] =
    useState(false);
  const [edicionListadoVisible, setEdicionListadoVisible] = useState(false);
  useEffect(() => {
    setMostrarModalEdicion(false);
    setMostrarModalAddCancion(false);
    setMostrarModalAddContenido(false);
  }, [loc]);

  useEffect(() => {
    setIdPresentacion(id);
    if (!celebracionActiva) {
      history.push("/");
    }
  }, [id]);

  useEffect(() => {
    setListadoEliminaOrdena([]);
    setReordenarDesabilitado(true);
    setEdicionListadoVisible(false);
    if (listadoCelebraciones.length > 0 && idPresentacion !== "") {
      if (canciones.length === 0) {
        getGruposBd();
        getcancionesTodas();
      }

      let cel = listadoCelebraciones.find(
        (d: ICelebracion) => d.Id === idPresentacion
      );
      cel.ListCancionesObj = cel.ListCancionesObj.sort((a: any, b: any) =>
        compare(a, b)
      );

      if (cel) {
        setCelebracion(cel);
        setDescripcion(cel.Descripción);
        setNombreCelebracion(cel.Nombre);
      }
      return;
    }
    if (!cargoCelebraciones) {
      //getListadoCelebraciones();
      setCargoCelebraciones(true);
    }
    //setIdPresentacion(idPresentacion);
  }, [idPresentacion, id]);

  function compare(a: any, b: any) {
    return a.Ord - b.Ord;
  }

  useEffect(() => {
    //console.log("EFX 2 Celebración PAGE");
    if (!agrego) return;
    let cel = listadoCelebraciones.find(
      (d: ICelebracion) => d.Id === idPresentacion
    );

    cel.ListCancionesObj = [];
    cel.Canciones?.forEach((can: any) => {
      let caObj: ICancion = JSON.parse(can);
      cel.ListCancionesObj?.push(caObj);
    });
    cel.ListCancionesObj = cel.ListCancionesObj.sort((a: any, b: any) =>
      compare(a, b)
    );

    if (cel) setCelebracion(cel);
    setAgrego(false);
  }, [agrego]);

  ///Para manejao de CancelarOrdenamiento
  useEffect(() => {
    if (cancelaOrdenaElimina) {
      setCancelaOrdenaElimina(false);
    }
  }, [cancelaOrdenaElimina]);

  useEffect(() => {
    if (seModificaCelebracion) {
      setAgrego(true);
      setSeModificaCelebracion(false);
    }
  }, [seModificaCelebracion]);

  const alertaEliminar = () => {
    present({
      // cssClass: 'my-css',
      header: "¡Atención!",
      message: "¿Realmente quieres eliminar esta lista?",
      buttons: [
        "Cancelar",
        { text: "Eliminar", handler: (d) => eliminarListado() },
      ],
      //onDidDismiss: (e) => console.log('did dismiss'),
    });
  };
  const alertActivar = (id: string) => {
    present({
      // cssClass: 'my-css',
      header: "ACTIVAR LISTADO",
      message:
        "¿Realmente quieres activar esta lista y hacerla visible para todos los usuarios?",
      buttons: [
        "Cancelar",
        { text: "Activar", handler: (d) => ActivarListado(id) },
      ],
      //onDidDismiss: (e) => console.log('did dismiss'),
    });
  };
  const eliminarListado = () => {
    eliminarListadoById(celebracion?.Id);
    setMostrarModalEdicion(false);
    history.push("/page/Celebraciones");
  };

  const ActivarListado = (id: string) => {
    activarListadoById(id);
    setMostrarModalEdicion(false);
    history.push("/page/Celebraciones");
  };

  const abrirAgregarCanciones = () => {
    if (canciones.length !== 0) {
      setMostrarModalAddCancion(true);
      setMostrarModalEdicion(false);
    }
  };
  const buscarCancion = (ev: any) => {
    let query: string = "";
    const target = ev.target as HTMLIonSearchbarElement;
    setValueSearch(target.value ? target.value : "");

    if (target) {
      query = target
        .value!.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    }

    if (query === "") {
      setResult([]);
      return;
    }
    if (canciones) {
      setResult(
        canciones.filter((d: ICancion) => {
          //busqueda en minusculas y sin acentos
          return (
            (d.Nombre
              ? d.Nombre.toLocaleLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(query) > -1
              : 0) ||
            (d.Cancion
              ? d.Cancion.toLocaleLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(query) > -1
              : 0)
          );
        })
      );
    }
  };

  const agregarACelebracionActual = (Id: string) => {
    setResult([]);

    if (!reordenarDesabilitado) {
      let can = canciones.find((ca: ICancion) => ca.Id === Id);
      can.TipoObj = "Cancion";

      let existe = listadoEliminaOrdena.find(
        (lisCan: any) => lisCan.Id === can.Id
      );

      if (existe) {
        presentToast({
          message: `¡ Esta canción ya esta en el listado !`,
          duration: 1500,
          position: "bottom",
        });
        return;
      }
      setListadoEliminaOrdena([...listadoEliminaOrdena, can]);
    } else {
      let existe2 = celebracion?.ListCancionesObj.find(
        (lisCan: any) => lisCan.Id === Id
      );

      if (existe2) {
        presentToast({
          message: `¡ Esta canción ya esta en el listado !`,
          duration: 1500,
          position: "bottom",
        });
        return;
      } else {
        addCancionesListado(Id, celebracion?.Id);
      }
    }

    setAgrego(true);
    presentToast({
      message: `Se agregó al listado`,
      duration: 1500,
      position: "bottom",
    });
    setValueSearch("");
  };

  const reodenarCanciones = (event: CustomEvent<ItemReorderEventDetail>) => {
    setListadoEliminaOrdena(event.detail.complete(listadoEliminaOrdena));
  };

  const borrarDelListadoOrdenado = (idCancion: string) => {
    let newList = listadoEliminaOrdena.filter((h) => h.Id !== idCancion);
    setListadoEliminaOrdena(newList);
  };

  const saveEliminaReordena = () => {
    let detallesCelebracion = { descripcion, nombreCelebracion };
    ordenarEliminardeCelebracion(
      listadoEliminaOrdena,
      idPresentacion,
      detallesCelebracion
    );
    setListadoEliminaOrdena([]);
    setReordenarDesabilitado(true);
    setAgrego(true);
    setEdicionListadoVisible(false);
    history.push("/page/Celebraciones");
  };
  const alertaDescartarOrden = () => {
    present({
      // cssClass: 'my-css',
      header: "DESCARTAR",
      message: "¿Realmente quieres descartar los cambios realizados?",
      buttons: [
        "Volver",
        {
          text: "Descartar",
          handler: () => {
            setListadoEliminaOrdena([]);
            setReordenarDesabilitado(true);
            setEdicionListadoVisible(false);
          },
        },
      ],
      //onDidDismiss: (e) => console.log('did dismiss'),
    });
  };

  const agregaContenido = () => {
    setMostrarModalEdicion(false);
    setMostrarModalAddContenido(true);
  };

  const advertirDescartarContenido = () => {
    present({
      // cssClass: 'my-css',
      header: "¡Atención!",
      message: "¿Quieres descartar los cambios realizados?",
      buttons: [
        "Volver",
        {
          text: "Descartar",
          handler: (d) => setMostrarModalAddContenido(false),
        },
      ],
      //onDidDismiss: (e) => console.log('did dismiss'),
    });
  };

  const abrirEdicionDeListado = () => {
    setEdicionListadoVisible(true);
    setReordenarDesabilitado(false);
    setMostrarModalEdicion(false);
    setListadoEliminaOrdena(
      celebracion?.ListCancionesObj ? [...celebracion?.ListCancionesObj] : []
    );
  };

  const getAlturaResultados = () => {
    if (!listRef2?.current?.clientHeight) {
      return 20;
    }
    if (
      listRef2?.current?.clientHeight >
      document.documentElement.offsetHeight / 1.8
    ) {
      return (
        document.documentElement.offsetHeight -
        document.documentElement.offsetHeight / 1.6
      );
    } else {
      return listRef2?.current?.clientHeight + 15;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>

          {/* <IonTitle>{celebracion?.Nombre}</IonTitle> */}
          <IonTitle className="ion-padding">
            {reordenarDesabilitado ? (
              celebracion?.Nombre
            ) : (
              <IonInput
                className="ion-margin-end --ion-color-step-150: #d9d9d9;"
                placeholder="Nombre de la celebración"
                onIonChange={(e) => {
                  setNombreCelebracion(`${e.target.value}`);
                }}
                value={nombreCelebracion}
              />
            )}
          </IonTitle>

          <IonIcon
            className="ion-margin-end"
            slot="end"
            icon={ellipsisVertical}
            onClick={() => setMostrarModalEdicion(true)}
          />
        </IonToolbar>
        {!reordenarDesabilitado ? (
          <IonFab slot="fixed" vertical="top" horizontal="end" edge={false}>
            <IonFabButton>
              <IonIcon icon={chevronDownCircle}></IonIcon>
            </IonFabButton>
            <IonFabList side="bottom">
              <IonFabButton onClick={() => saveEliminaReordena()}>
                <IonIcon icon={save}></IonIcon>
              </IonFabButton>
              <IonFabButton
                onClick={() => {
                  alertaDescartarOrden();
                }}
              >
                <IonIcon icon={closeCircle}></IonIcon>
              </IonFabButton>
            </IonFabList>
          </IonFab>
        ) : null}
      </IonHeader>
      <IonContent scrollY={true}>
        {celebracion ? (
          <>
            <IonTitle className="ion-padding">
              {reordenarDesabilitado ? (
                celebracion.Descripción
              ) : (
                <IonInput
                  className="ion-margin-end --ion-color-step-150: #d9d9d9;"
                  placeholder="Indica una descripción"
                  onIonChange={(e) => {
                    setDescripcion(`${e.target.value}`);
                  }}
                  value={descripcion}
                />
              )}
            </IonTitle>

            <div className="ion-content-scroll-host">
              <IonList lines="inset">
                <IonReorderGroup
                  disabled={reordenarDesabilitado}
                  onIonItemReorder={reodenarCanciones}
                >
                  {(reordenarDesabilitado
                    ? celebracion.ListCancionesObj
                    : listadoEliminaOrdena
                  )?.map((c: ICancionCelebracion, ind) => {
                    return (
                      <IonItem
                        key={`celTit${ind}`}
                        id={c.Id}
                        onClick={() => {
                          if (reordenarDesabilitado) {
                            setCancionCelebracionVisible(c);
                            history.push(
                              c.TipoObj !== "Contenido"
                                ? "/page/CancionDeCelebracion"
                                : "/page/ContenidoCelebracion",
                              {
                                rutaOrigen: `/page/CelebracionPage/${celebracion.Id}`,
                                cancion: c,
                              }
                            );
                          }
                        }}
                      >
                        {c.AcordeBase ? (
                          <IonBadge
                            style={{ margin: "4px" }}
                            color={"secondary"}
                          >
                            {c.AcordeBase}
                          </IonBadge>
                        ) : null}

                        {!reordenarDesabilitado ? (
                          <IonIcon
                            color="danger"
                            slot="start"
                            icon={closeCircle}
                            style={{ margin: "4px" }}
                            onClick={() => {
                              borrarDelListadoOrdenado(c.Id);
                            }}
                          />
                        ) : null}

                        <IonLabel>{c.Nombre}</IonLabel>

                        <IonReorder id={c.Id} slot="end"></IonReorder>
                      </IonItem>
                    );
                  })}
                </IonReorderGroup>
              </IonList>
            </div>
          </>
        ) : mostrarLoading ? (
          <IonSpinner name="crescent" />
        ) : null}
      </IonContent>
      <IonFooter translucent={true}>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              {!edicionListadoVisible ? (
                <IonCol>
                  <IonButton
                    expand="full"
                    fill="outline"
                    color="medium"
                    shape="round"
                    onClick={abrirEdicionDeListado}
                  >
                    Editar Listado
                  </IonButton>
                </IonCol>
              ) : (
                <>
                  <IonCol>
                    <IonButton
                      expand="full"
                      fill="outline"
                      color="medium"
                      shape="round"
                      onClick={() => setMostrarModalAddCancion(true)}
                    >
                      <IonIcon icon={add} />
                      <IonIcon icon={musicalNote} />
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      expand="full"
                      fill="outline"
                      color="medium"
                      shape="round"
                      onClick={() => setMostrarModalAddContenido(true)}
                    >
                      <IonIcon icon={add} />
                      <IonIcon icon={cubeOutline} />
                    </IonButton>
                  </IonCol>
                </>
              )}
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>

      <IonModal
        ref={modalEdicion}
        // trigger="open-edit"
        onWillDismiss={() => setMostrarModalEdicion(false)}
        isOpen={mostrarModalEdicion && !mostrarLoading}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => modalEdicion.current?.dismiss()}>
                <IonIcon slot="end" icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>{celebracion?.Nombre}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {celebracion?.Canciones?.length !== 0 ? (
            <IonItem lines="none">
              <IonButtons
                slot="start"
                onClick={() =>
                  alertActivar(idPresentacion ? idPresentacion : "")
                }
              >
                <IonIcon slot="start" icon={power} />
                <IonText color="medium">
                  <h4>Activar Listado</h4>
                </IonText>
              </IonButtons>
            </IonItem>
          ) : null}
          <IonItem lines="none">
            <IonButtons slot="start" onClick={() => abrirAgregarCanciones()}>
              <IonIcon slot="start" icon={add} />
              <IonText color="medium">
                <h4>Agregar Canciones</h4>
              </IonText>
            </IonButtons>
          </IonItem>
          {celebracion?.Canciones?.length !== 0 ? (
            <IonItem lines="none">
              <IonButtons
                slot="start"
                onClick={() => {
                  abrirEdicionDeListado();
                }}
              >
                <IonIcon slot="start" icon={pencil} />
                <IonText color="medium">
                  <h4>Editar Listado</h4>
                </IonText>
              </IonButtons>
            </IonItem>
          ) : null}
          <IonItem lines="none">
            <IonButtons slot="start" onClick={() => agregaContenido()}>
              <IonIcon slot="start" icon={add} />
              <IonText color="danger">
                <h4>Agregar un contenido</h4>
              </IonText>
            </IonButtons>
          </IonItem>
          <IonItem lines="none">
            <IonButtons slot="start" onClick={() => alertaEliminar()}>
              <IonIcon slot="start" icon={trash} />
              <IonText color="danger">
                <h4>Eliminar Listado</h4>
              </IonText>
            </IonButtons>
          </IonItem>
        </IonContent>
      </IonModal>

      <IonModal
        ref={modalAddCanciones}
        onWillDismiss={() => {
          setMostrarModalAddCancion(false);
          setResult([]);
          setAgrego(true);
        }}
        isOpen={mostrarModalAddCancion}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => modalAddCanciones.current?.dismiss()}>
                <IonIcon slot="end" icon={close} />
              </IonButton>
            </IonButtons>
            <IonRow>
              <IonCol size="12">
                <IonSearchbar
                  autocomplete="name"
                  placeholder="Buscar canciones"
                  showClearButton="focus"
                  color="light"
                  onIonChange={(ev) => buscarCancion(ev)}
                  value={valueSearch}
                />
              </IonCol>
            </IonRow>
          </IonToolbar>
          {result.length > 0 ? (
            <IonContent
              style={{
                height: getAlturaResultados(),
              }}
              scrollY={true}
              color="light"
            >
              <IonList
                style={{
                  marginTop: "-0.4rem",
                  backgroundColor: "#f5f6f9",
                }}
                inset={true}
                ref={listRef2}
              >
                {result.map((ca: ICancion, indx) => (
                  <IonItem
                    onClick={() => agregarACelebracionActual(ca.Id)}
                    color="light"
                    key={indx}
                  >
                    <IonText>
                      <h5>{ca.Nombre}</h5>
                      <p style={{ fontSize: "smaller", marginTop: "-5px" }}>
                        {ca.Descripcion}
                      </p>
                    </IonText>
                  </IonItem>
                ))}
              </IonList>
            </IonContent>
          ) : null}
        </IonHeader>
        <IonContent>
          <>
            <Swiper onSwiper={(swiper) => setSwiper(swiper)}>
              {grupoCanciones && mostrarModalAddCancion ? (
                grupoCanciones.map((g: IGrupoCanciones) => (
                  <SwiperSlide key={`swipe_${g.Id}`}>
                    <CardGrupo
                      key={`cardGrupo_${g.Id}`}
                      grupo={g}
                      idCelebracion={celebracion?.Id}
                      listaPresente={
                        celebracion?.Canciones ? celebracion.Canciones : []
                      }
                      reordenarDesabilitado={reordenarDesabilitado}
                      setListadoEliminaOrdena={setListadoEliminaOrdena}
                      listadoEliminaOrdena={listadoEliminaOrdena}
                      fn_Agrego={setAgrego}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <h2>No se encontraron Grupos</h2>
              )}
            </Swiper>
          </>
        </IonContent>
      </IonModal>

      <ModalContenido
        Contenido={null}
        idPresentacion={idPresentacion}
        isOpen={mostrarModalAddContenido}
        setOpenClose={setMostrarModalAddContenido}
        setAgrego={setAgrego}
        reordenarDesabilitado={reordenarDesabilitado}
        setListadoEliminaOrdena={setListadoEliminaOrdena}
        listadoEliminaOrdena={listadoEliminaOrdena}
      />
    </IonPage>
  );
};

export default CelebracionPage;
