import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import { useHistory, useLocation } from "react-router-dom";
import {
  archiveSharp,
  bookmarkOutline,
  heartOutline,
  heartSharp,
  logOut,
  musicalNoteOutline,
  musicalNoteSharp,
  paperPlaneOutline,
  radio,
} from "ionicons/icons";
import "./Menu.css";

import UserContext from "../contextManagment/user/userContext";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";
import { useContext, useEffect, useState } from "react";

interface AppPage {
  id: number;
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  orden: number;
}

let listPageTodos: AppPage[] = [
  {
    id: 1,
    title: "Banco Canciones",
    url: "/page/BancoCancionesPage",
    iosIcon: paperPlaneOutline,
    mdIcon: archiveSharp,
    orden: 1,
  },
  {
    id: 2,
    title: "Celebraciones",
    url: "/page/Celebraciones",
    iosIcon: musicalNoteOutline,
    mdIcon: musicalNoteSharp,
    orden: 2,
  },
  {
    id: 3,
    title: "Celebración Activa",
    url: `/`,
    iosIcon: radio,
    mdIcon: radio,
    orden: 3,
  },
  {
    id: 4,
    title: "Nueva Cancion",
    url: "/page/CancionPage/new",
    iosIcon: heartOutline,
    mdIcon: heartSharp,
    orden: 4,
  },
  {
    id: 5,
    title: "Salir",
    url: "/page/LogOut",
    iosIcon: logOut,
    mdIcon: logOut,
    orden: 5,
  },
  {
    id: 6,
    title: "Nuevo Usuario",
    url: "/page/CreacionUsuarioPage",
    iosIcon: bookmarkOutline,
    mdIcon: bookmarkOutline,
    orden: 6,
  },
];

const GetMenuByPerfil = (userProfile: any) => {
  let list: AppPage[] = [];
  if (!userProfile) return [];

  if (userProfile.Roles.includes("Cantante")) {
    list = listPageTodos.filter((p) => {
      if (p.id !== 6 && p.id !== 2 && p.id !== 4 && p.id !== 1) {
        return p;
      }
    });
  }

  if (userProfile.Roles.includes("Músico")) {
    list = listPageTodos.filter((p) => {
      if (p.id !== 6 && p.id !== 2 && p.id !== 4) {
        return p;
      }
    });
  }

  if (userProfile.Roles.includes("Director")) {
    list = listPageTodos.filter((p) => {
      return p.id !== 6;
    });
  }

  if (userProfile.Roles.includes("Administrador")) {
    list = listPageTodos.sort(ordenarMenu);
  }

  return list.sort(ordenarMenu);
};

const MenuCelebracionActiva = (organizacion: string) => {
  return {
    title: "Celebración Activa",
    url: `/comunidad/${organizacion ? organizacion : "JEM"}`,
    iosIcon: radio,
    mdIcon: radio,
    orden: 3,
  };
};

const MenuCreacionUsuario = () => {
  return {
    title: "Nuevo Usuario",
    url: "/page/CreacionUsuarioPage",
    iosIcon: bookmarkOutline,
    mdIcon: bookmarkOutline,
    orden: 6,
  };
};

function ordenarMenu(a: AppPage, b: AppPage) {
  return a.orden - b.orden;
}

const Menu: React.FC<{ userCorreo: string; organizacion: string }> = ({
  userCorreo,
  organizacion,
}) => {
  const { profileUser } = useContext(UserContext);

  const {
    comunidadesTodas,
    setUsuarioComunidad,
    usuarioComunidad,
    setActualizarListado,
    setEscuchaActiva,
    setReproduciendo,
    setUserLocation,
    userLocation,
    setProfileUserConected,
    // escuchaPresentacionActiva,
  } = useContext(CelebracionesContext);
  const location = useLocation();
  const [comunidadSeleccionada, setComunidadSeleccionada] =
    useState<string>("");
  //const [appPages, setAppPages] = useState<AppPage[] | []>([]);
  const history = useHistory();

  useEffect(() => {
    setReproduciendo(false);
  }, [location]);

  useEffect(() => {
    setEscuchaActiva(false);
    setComunidadSeleccionada(usuarioComunidad);
    if (profileUser) {
      setProfileUserConected(profileUser);
    }
    //Agrega menu celebración activa segun comunidad de  usuario
    setActualizarListado(true);
  }, [usuarioComunidad]);

  const nombresComunidadesById = (id: string) => {
    if (!comunidadesTodas) return "";
    return comunidadesTodas.find((com: any) => com.id === id)?.Nombre;
  };

  const cambioDeComunidad = (e: any) => {
    setComunidadSeleccionada(e.detail.value);
    setUsuarioComunidad(e.detail.value);
    if (location.pathname.includes("CelebracionPage")) {
      history.push(`/page/Celebraciones/`);
    }
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Cancionero</IonListHeader>
          <IonNote>{userCorreo}</IonNote>
          {profileUser
            ? GetMenuByPerfil(profileUser).map((appPage, index) => {
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === appPage.url ? "selected" : ""
                      }
                      routerLink={appPage.url}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <IonIcon
                        slot="start"
                        ios={appPage.iosIcon}
                        md={appPage.mdIcon}
                      />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              })
            : null}
        </IonList>
        {profileUser?.Comunidades.length > 1 ? (
          <IonList>
            <IonLabel>Tus comunidades</IonLabel>
            <IonItem>
              <IonSelect
                placeholder="Comunidad"
                value={usuarioComunidad}
                onIonChange={(e) => {
                  cambioDeComunidad(e);
                }}
              >
                {profileUser.Comunidades.map((com: any) => (
                  <IonSelectOption key={com} value={com}>
                    {nombresComunidadesById(com)}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonList>
        ) : (
          <IonList>
            <IonLabel color="medium">Comunidad</IonLabel>
            <IonRadioGroup
              allowEmptySelection={false}
              value={userLocation}
              onIonChange={(e) => {
                setUserLocation(e.detail.value);
              }}
            >
              <IonItem>
                <IonLabel>{`${
                  comunidadesTodas.find((c: any) => c.id === organizacion)
                    ?.Nombre
                }`}</IonLabel>
                <IonRadio value="user">user</IonRadio>
              </IonItem>
              <IonItem>
                <IonLabel>Por ubicación</IonLabel>
                <IonRadio value="location">location</IonRadio>
              </IonItem>
            </IonRadioGroup>
          </IonList>
        )}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
