import React, { useReducer } from "react";
import UserContext from "./userContext";
import UserReducer from "./userReducer";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { getFirestore, setDoc, doc, getDoc } from "firebase/firestore";
import {
  SET_USER_ACTIVE,
  SET_USER_ERROR,
  SET_LOADING_USER,
  SET_USER_CREADO,
  SET_PROFILE_USER,
} from "../types";

//============================>>>   Desde aca se inicializa firebase no eliminar ===============================
import { loginUser } from "../../config/firebaseConfig";
//==================================================================================================
// import React, { useReducer } from 'react';
// import CancionesContext from './CancionesContext';
// import CancionReducer from './CancionesReducer';
// import { ALMACENAR_CANCIONES_ENCONTRADAS, EDITAR_CANCION,LLAMAR_CANCION_GRUPO } from "../types/index"
// //import  {v4 as uuid}  from 'uuid';
// import clienteAxios from '../../../Utils/ClienteAxios';

const UserState = (props) => {
  const initialState = {
    userActive: null,
    reloadInicio: false,
    profileUser: null,
  };
  //Dispatch para ejecutar las acciones
  const [state, dispatch] = useReducer(UserReducer, initialState);
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (!state.userActive)
        dispatch({
          type: SET_USER_ACTIVE,
          payload: user,
        });
    } else {
    }
  });

  //Serie de funciones para CRUD de proyectos
  const loginUsuario = async (usr) => {
    if (state.userActive) return;
    dispatch({
      type: SET_LOADING_USER,
      payload: true,
    });

    signInWithEmailAndPassword(auth, usr.email, usr.password)
      .then((userCredential) => {
        dispatch({
          type: SET_USER_ACTIVE,
          payload: userCredential.user,
        });
        return userCredential.user;
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log("error.code: ", error.code);
        const errorMessage = error.message;
        dispatch({
          type: SET_USER_ERROR,
          payload: error.message,
        });
      });
  };

  const LimpiarErrorFirebaseLogin = () => {
    dispatch({
      type: SET_USER_ERROR,
      payload: "",
    });
  };

  const loginOutUser = async () => {
    // let auth = getAuth();
    dispatch({
      type: SET_LOADING_USER,
      payload: true,
    });

    signOut(auth)
      .then(() => {
        dispatch({
          type: SET_USER_ACTIVE,
          payload: null,
        });
        dispatch({
          type: SET_PROFILE_USER,
          payload: null,
        });
      })
      .catch((error) => {
        //const errorCode = error.code;
        const errorMessage = error.message;
        console.log("errorMessage: ", errorMessage);
      });
  };

  const registrar = async (modelLogin) => {
    LimpiarErrorFirebaseLogin();
    dispatch({
      type: SET_LOADING_USER,
      payload: true,
    });

    const nuevousuario = await createUserWithEmailAndPassword(
      auth,
      modelLogin.Correo,
      modelLogin.Password
    )
      .then((userCredential) => {
        // Signed in
        return userCredential.user;
      })
      .catch((error) => {
        //const errorCode = error.code;
        dispatch({
          type: SET_USER_ERROR,
          payload: error.message,
        });
      });

    await updateProfile(auth.currentUser, {
      displayName: modelLogin.Nombre,
      phonoNumber: null,
      photoURL: modelLogin.Organizacion,
    })
      .then(() => {
        // Profile updated!
        // ...
      })
      .catch((error) => {
        dispatch({
          type: SET_USER_ERROR,
          payload: error,
        });
      });

    const db = getFirestore();
    setDoc(doc(db, "UserProfiles", nuevousuario.uid), {
      Nombre: modelLogin.Nombre,
      Organizacion: modelLogin.Organizacion,
      Comunidades: [...modelLogin.Comunidades],
      Correo: modelLogin.Correo,
      Roles: modelLogin.Roles,
    });

    dispatch({
      type: SET_USER_CREADO,
      payload: nuevousuario,
    });
  };

  const setProfileUser = async () => {
    if (state.profileUser) return state.profileUser;
    if (state.userActive) {
      const db = getFirestore();
      const docRef = doc(db, "UserProfiles", state.userActive.uid);
      const docSnap = await getDoc(docRef);
      let profile;
      if (docSnap.exists()) {
        profile = docSnap.data();
        profile.id = docSnap.id;
      } else {
        console.log("No such profile!");
      }
      dispatch({
        type: SET_PROFILE_USER,
        payload: profile,
      });
      return profile;
    }
  };

  return (
    <UserContext.Provider
      value={{
        userActive: state.userActive,
        userError: state.userError,
        loadingFromUser: state.loadingFromUser,
        nuevoUsuarioCreado: state.nuevoUsuarioCreado,
        profileUser: state.profileUser,
        loginUsuario,
        loginOutUser,
        LimpiarErrorFirebaseLogin,
        registrar,
        setProfileUser,
        // cancionAEditar: state.cancionAEditar,
        // listaGeneralCanciones: state.listaGeneralCanciones,
        // LlamarCancionesPorGrupo,
        // EditarCancion,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
export default UserState;
